@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300&family=Roboto&family=Big+Shoulders+Stencil+Text:wght@100;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@100..900&display=swap');

:root {
  --lightgrey: #acadb1;
  --grey: #424656;
  --darkgrey: #2b2929;
  --black: #09090C;
  --lightblue: #29bfff;
  --blue: #4568b0;
  --darkblue: #283d67;
  --lightbrown: #b08d45;
  --brown: #2c2311;

  --hex-lightgrey: #acadb1;
  --hex-grey: #424656;
  --hex-darkgrey: #2b2929;
  --hex-black: #09090C;
  --hex-lightblue: #29bfff;
  --hex-blue: #4568b0;
  --hex-darkblue: #283d67;
  --hex-lightbrown: #b08d45;
  --hex-brown: #2c2311;

  --hsl-lightgrey: 220, 43%, 96%;
  --hsl-grey: 228, 13%, 30%;
  --hsl-darkgrey: 0, 2%, 16%;
  --hsl-black: 240, 14%, 4%;
  --hsl-blue: 220, 44%, 48%;
  --hsl-darkblue: 220, 44%, 28%;
  --hsl-lightbrown: 40, 44%, 48%;
  --hsl-brown: 40, 44%, 12%;

  /* Update color  & font roots */
  --color-rgb-darkgrey-new: 43, 41, 41;
/* --color-rgb-darkgrey: #2b2929; */
  --font-fancy: 'Encode Sans SC', Helvetica, sans-serif;
  --font-standard: 'Open Sans', Helvetica, sans-serif;

  --h1-px: 48px;
  --h2-px: 36px;
  --h3-px: 24px;
  --h4-px: 20px;
  --p-px: 18px;

  /* --h1-vw: 5.5vw; */
  --h2-vw: 4.5vw;
  /* --h3-vw: 3.25vw; */
  --h4-vw: 2.5vw;
  --p-vw: 2.25vw;

  --background-color: var(--darkgrey);
  --hex-background-color: var(--darkgrey);
  --small-section-padding: 80px;
  --large-section-padding: 64px;
}

@media (max-width: 749px) {
  :root {
    /* --h1-vw: 5.5vw; */
    --h2-vw: 9.25vw;
    /* --h3-vw: 3.25vw; */
    --h4-vw: 5.25vw;
    --p-vw: 4.625vw;
  }
}

body {
  color: var(--hex-black);
  margin: 0;
  font-family: var(--font-standard);
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  cursor: pointer;
  text-decoration: none;
  transition: .3s ease;
  color: var(--hex-lightblue);
}

a:hover {
  color: var(--hex-lightbrown);
}

a:visited {
  color: var(--hex-lightgrey);
}
svg * {
  transition: .3s ease;
}
h1,h2,h3,h4,h5 {
  margin-block-start: 0;
  margin-block-end: 0;
  line-height: 100%;
}
h1, h2 {
  font-family: var(--font-fancy);
  font-weight: 300;
  margin-bottom: 1rem;
}
h1 {
  font-size: var(--h1-px);
}
h2 {
  font-size: var(--h2-px);
}
h3 {
  font-size: var(--h3-px);
}
h4 {
  font-size: var(--h4-px);
}
p {
  font-size: var(--p-px);
}

a, h1, h2, h3, h4, h5, p, li {
  letter-spacing: .055rem;
}
/* + ✚ ＋ ﹢ */
ul { 
  list-style-type: '﹢  ';
}
/* Desktop vs Mobile toggle */
.mobile-block, .mobile-inline {
  display: none!important;
}
.desktop-block {
  display: block;
} 
.desktop-inline {
  display: inline-block;
}
@media screen and (max-width: 945px){
  .desktop-block, .desktop-inline {
    display: none!important;
  }
  .mobile-block {
    display: block!important;
  } 
  .mobile-inline {
    display: inline-block!important;
  }
}

.no-break {
  display: inline-block!important;
}

/*==================== Set page width standards ====================*/
.page-width {      
  max-width: 800px;
  margin: 0 auto;
}  



/*==================== NavBar STYLE ====================*/
.nav-bar svg {
  fill: var(---color);
  display: inline-block;
}
.nav-bar > #hamburger-btn > section {
  background-color: var(---color);;
}

.nav-bar.blue {
  ---color: var(--hex-blue);
}
.blue:hover {
  ---color: #FFF;
}
.nav-bar.home,
.nav-bar.white {
  ---color: #FFF;
}
.white:hover {
  ---color: var(--hex-blue);
  }
.nav-bar.home svg {
  display: none;
}
.nav-bar.info svg {
  ---color: #FFF;
}
.nav-bar.info > #hamburger-btn > section {
  ---color: var(--hex-blue);
}
/*==================== HAMBURGER ICON ====================*/
.hamburger-close {
  padding-top: 10px;
}
.hamburger-close > section {
  margin: 8px 0!important;
}
.hamburger-close > section:first-of-type {
  transform: rotate(45deg)!important;
}
.hamburger-close > section:last-of-type {
  transform: rotate(-45deg)!important;
  margin-top: -12px!important;
}  

#hamburger-btn > section {
  margin: 6px 0;
  width: 35px;
  height: 4px;
  background-color: #fff;
  transition: all .3s ease;
  /* border-radius: 3px; */
}
@media screen and (orientation: portrait) and (max-width: 940px) {
  /* #hamburger-btn {
    display: block;
  } */
}



.header {
  font-family: var(--font-fancy);
  font-size: 48px;
  letter-spacing: 4px;
  margin: 0 auto!important;
}
.top-header {
  padding-top: 18px;
}
/* ON/OFF MODAL SWITCH */
#modal.active {
  max-width: 100vw;
}
#menu.active {
  max-width: 40vw;
}
.active {
  max-height: 100vh;
  width: 100%;
  height: 100%;
  transition: max-width .8s ease-in-out;
}

#modal.inactive{
  max-height: 0px;
}
#menu.inactive {
  max-height: 100vh;
  font-size: 1px;
  color: transparent!important;
}
.inactive {
  max-width: 0;
  transition: .8s ease-in-out;
  transition-delay: .3s !important;
}

@media screen and (max-width: 945px){
  #menu.active, #modal.active {
    max-width: 100vw;
  }
  .active {
    max-width: 100vw;
  }
}

a.active {
  color:var(--hex-lightgrey)!important;
}


.index-wrapper {
  scroll-snap-type: y mandatory;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
} 
.index-wrapper > div, .container {
  --padding-top: 18px;
  position: relative;
  width: 100vw;
  background-color: var(--hex-background-color);
  overflow: scroll;
  padding-top: var(--padding-top);
  height: calc(100dvh - var(--padding-top));
}



.scroll-snap-start {
  -webkit-scroll-snap-align: start;
  -moz-scroll-snap-align: start;
  -ms-scroll-snap-align: start;
  scroll-snap-align: start;
}


/* hide intersection observer before intersection reveal */



/* turn a list from column to row */
.horizontal-list {
  padding-inline-start: 0;
  text-align: center;
}
.horizontal-list li {
  display: inline-block;
  padding: 0 24px;
}
.horizontal-list li + li {
  border-left: 1px solid var(--hex-blue);
}

/* Email Form */
button.selectedHighlighted {
  background-color: rgba(41, 191, 255, .3)!important;
  /* color: #fff; */
}
/* Background Phase */
@keyframes slideleft {
  0% { background-position: 0%; }
  50% { background-position: 100%; }
  100% { background-position: 0%; }
}



/* Down Arrow Animation */
@-webkit-keyframes bounce {
  20%, 53%, 80%, 93%, 98%, 0%, 100% { transform: translateY(0px); opacity: 1}
  40%, 43% { transform: translateY(-20px); opacity: .0; }
  70%, 73% { transform: translateY(-10px); opacity: .1; }
  90%, 93% { transform: translateY(-5px); opacity: .4; }
  96%, 97% { transform: translateY(-2px); opacity: .9; }
}
@-moz-keyframes bounce {
  20%, 53%, 80%, 93%, 98%, 0%, 100% { transform: translateY(0px); opacity: 1}
  40%, 43% { transform: translateY(-20px); opacity: .0; }
  70%, 73% { transform: translateY(-10px); opacity: .1; }
  90%, 93% { transform: translateY(-5px); opacity: .4; }
  96%, 97% { transform: translateY(-2px); opacity: .9; }
}
@keyframes bounce {
  20%, 53%, 80%, 93%, 98%, 0%, 100% { transform: translateY(0px); opacity: 1}
  40%, 43% { transform: translateY(-20px); opacity: .0; }
  70%, 73% { transform: translateY(-10px); opacity: .1; }
  90%, 93% { transform: translateY(-5px); opacity: .4; }
  96%, 97% { transform: translateY(-2px); opacity: .9; }
}

#down-arrow-container {
  position: absolute;
  bottom: 10vh;
  width: 100vw;
  height: 40px;
  text-align: center;
  cursor: pointer;
  font-weight: regular;
  font-size: 24px;
  text-shadow: 2px 2px 2px rgba(0,0,0,.4);
}
@media screen and (min-width: 640px) {
  #down-arrow-container {
    bottom: 40px;
  }
}
#down-arrow ,
.down-arrow-class {
  position: relative;
  text-align: center;
  padding: 1px;
  margin: 0 auto;
  height: 1px;
  width: 100px;
}
#down-arrow {
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
#down-arrow:before,
.down-arrow-class:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 51%;
  background: white;
  transform: skew(0deg, 20deg);
}
#down-arrow:after,
.down-arrow-class:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background: white;
  transform: skew(0deg, -20deg);
}
.down-arrow-class:before,
.down-arrow-class:after {
  background: rgba(0,0,0,.25);
}
#down-arrow:before,
#down-arrow:after {
  background: #fff;
}